<template>
	<v-container fluid>
		<v-card max-width="600" class="mx-auto mt-5" rounded="xl">
			<v-card-title>
				<h1 class="display-1">
					{{ $t('auth.verify') }}
				</h1>
			</v-card-title>
			<v-card-text v-if="authenticated && !verificationAttemp">
				<p>{{ $t('invoices.verifyEmailSent', { email: company.invoice.email }) }}.</p>
				<small class="form-text text-muted">{{ $t('auth.verifyEmailSentDuration') }} {{ $t('auth.verifyEmailSentSpam') }}</small>
			</v-card-text>

			<v-card-text v-else-if="verificationAttemp && !verified">
				<p>{{ $t('invoices.verificationFailed') }}</p>
			</v-card-text>

			<v-card-text v-else-if="authenticated && verificationAttemp && verified">
				<p>{{ $t('invoices.verificationSucceededLoggedIn') }}</p>
			</v-card-text>

			<v-card-text v-else-if="!authenticated && verificationAttemp && verified">
				<p>{{ $t('invoices.verificationSucceededNotLoggedIn') }}</p>
			</v-card-text>

			<v-divider />

			<v-card-actions>
				<v-btn v-if="authenticated && !verificationAttemp" color="primary" @click="callSendVerifyInvoiceEmail()">
					{{ $t('auth.resendEmail') }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('invoices.invoiceVerification')
		}
	},
	data() {
		return {
			verificationAttemp: false,
			verified: false
		}
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			company: 'companies/company',
			setupInfo: 'user/setupInfo'
		})
	},
	created() {
		this.open()
		if (this.$route.query.token) {
			this.verifyInvoice(this.$route.query.token).then(({ success }) => {
				this.close()
				this.verificationAttemp = true
				this.verified = success
				this.refreshAccess()
				return
			})
		} else {
			// Make sure the user is logged in or not by refreshing the token
			this.refreshAccess()
				.then(({ success }) => {
					if (success && this.setupInfo.verified && !this.setupInfo.joinedCompany) {
						this.$router.push({ name: 'AuthSetup' })
					} else if (success && this.setupInfo.verified && this.setupInfo.joinedCompany && this.setupInfo.company.invoiceVerified) {
						this.$router.push({ name: 'SettingsSubscriptions' })
					} else if (!success) {
						this.$router.push({ name: 'Landing' })
					}
				})
				.catch((error) => {
					console.error(error)
					this.close()
				})
				.then(() => {
					this.close()
				})
		}
	},
	methods: {
		callSendVerifyInvoiceEmail() {
			this.sendVerifyInvoiceEmail()
		},
		...mapActions('auth', ['refreshAccess']),
		...mapActions('invoices', ['sendVerifyInvoiceEmail', 'verifyInvoice']),
		...mapActions('splash', ['open', 'close'])
	}
}
</script>
